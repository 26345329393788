import vSelect from 'vue-select'
import { teeSheet } from '@/api/tee-sheet'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { booking } from '@/api/booking'

export default {
  components: {
    Cleave,
    vSelect,
    required,
    ValidationProvider,
    ValidationObserver,
    VSwatches,
  },
  props: ['courses', 'StartDate'],
  data() {
    return {
      inputMaskTime: {
        time: true,
        timePattern: ['h', 'm'],
      },
      TeeTimeType: 'STANDARD',
      StartTime: '05:00',
      EndTime: '18:00',
      ListTeeTime: '',
      Frequence: null,
      FrequenceDefalut: null,
      ApplyDayType: { title: 'Chỉ 1 ngày', value: 'ONLYDAY' },
      // StartDate: this.getUTC('start', 'day'),
      EndDate: this.getUTC('start', 'day', 1),
      WeeklyCode: '',
      CourseId: '',
      courseSelected: [],
      dayOption: [
        { title: 'Chỉ 1 ngày', value: 'ONLYDAY' },
        { title: 'Hàng ngày', value: 'DAILY' },
        { title: 'Hàng tuần', value: 'WEEKLY' },
      ],
      lightOfDay: {
        selected: null,
        dayInWeek: [
          { name: 'T2', value: 1 },
          { name: 'T3', value: 2 },
          { name: 'T4', value: 3 },
          { name: 'T5', value: 4 },
          { name: 'T6', value: 5 },
          { name: 'T7', value: 6 },
          { name: 'Chủ nhật', value: 7 },
        ],
      },
      errorLimitInput: false,
      Color: 'Red',
      numberPlayerInTee: 4,
      isUpNumberOnFly: false,
      disableButton: false
    }
  },
  watch: {
    Frequence(newValue, _) {
      if ((Number(newValue) >= 1 && Number(newValue) <= 60) || newValue === '') {
        this.errorLimitInput = false
        return
      }
      this.errorLimitInput = true
    },
    courses(newValue)
    {
      this.courseSelected = newValue
    },
  },
  created() {
    this.getConfigInfo_RCOM01()
  },
  methods: {
    handleChangeMode(mode) {
      this.TeeTimeType = mode
    },

    handleClickLightOfDay(value) {
      this.lightOfDay.selected = value
    },

    splitListTeeTime(listTeeTime) {
      if (!listTeeTime) return null
      return listTeeTime.split(',')
    },

    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },

    async saveTee_API_ITT01() {
      const startDate = this.dayjs(new Date(this.StartDate).toLocaleString('en-US', { timeZone: 'UTC' })).add(+this.StartTime.split(':')[0], 'hours').add(+this.StartTime.split(':')[1], 'minutes').format('YYYY-MM-DDTHH:mm:ss')
      const endDate = this.dayjs(new Date(this.EndDate).toLocaleString('en-US', { timeZone: 'UTC' })).add(+this.EndTime.split(':')[0], 'hours').add(+this.EndTime.split(':')[1], 'minutes').format('YYYY-MM-DDTHH:mm:ss')
      const endTime = this.dayjs(new Date(this.StartDate).toLocaleString('en-US', { timeZone: 'UTC' })).add(+this.EndTime.split(':')[0], 'hours').add(+this.EndTime.split(':')[1], 'minutes').format('YYYY-MM-DDTHH:mm:ss')

      const listTeeTime = this.ListTeeTime ? this.splitListTeeTime(this.ListTeeTime).map(x => this.dayjs(new Date(this.StartDate).toLocaleString('en-US', { timeZone: 'UTC' })).add(+x.split(':')[0], 'hours').add(+x.split(':')[1], 'minutes').format('YYYY-MM-DDTHH:mm:ss') + 'Z')  : []
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.disableButton = true
          const isStandardMode = this.TeeTimeType === 'STANDARD'

          const TeeTimeType = this.TeeTimeType?.toString()
          const StartTime = isStandardMode ? `${startDate}Z` : null
          const EndTime = isStandardMode ? `${endTime}Z` : null
          const ListTeeTime = listTeeTime
          const Frequence = Number(this.Frequence)
          const NumberOfGolfer = this.numberPlayerInTee
          const ApplyDayType = this.ApplyDayType?.value.toString()
          const StartDate = `${startDate}Z` // dayjs(this.StartDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          const EndDate = this.ApplyDayType === 'ONLYDAY' ? `${endTime}Z` : `${endDate}Z` // dayjs(this.EndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          const WeeklyCode = isStandardMode ? [] : null
          const CourseId = this.courseSelected.map(item => item.Id)
          const { Color } = this

          const data = {
            TeeTimeInfo: {
              TeeTimeType,
              StartTime,
              EndTime,
              ListTeeTime,
              Frequence,
              NumberOfGolfer,
              ApplyDayType,
              StartDate,
              EndDate,
              WeeklyCode,
              CourseId,
              Color,
            },
          }

          const response = await teeSheet.saveTee(data)
          await this.showSuccess(response, this.$t('golf_tee_sheet_save_success'))
          this.disableButton = false
          this.$refs.simpleRules.reset()
        }
      })
    },

    async showSuccess(response, mess) {
      this.showResToast(response)
      if (response.Status === '200') {
        this.$emit('event', { type: 'save_success' })
        this.refreshData()
        this.hideSideBar()
      }
    },

    hideModal() {
      this.$bvModal.hide('modal-add-tee')
    },

    validateBeforSubmit() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.$bvModal.show('modal-add-tee')
        }
      })
    },
    refreshData() {
      this.TeeTimeType = 'STANDARD'
      this.StartTime = '05:00'
      this.EndTime = '18:00'
      this.ListTeeTime = ''
      this.Frequence = this.FrequenceDefalut
      this.numberPlayerInTee = 4
      this.ApplyDayType = { title: 'Chỉ 1 ngày', value: 'ONLYDAY' }
      this.StartDate = this.getUTC('start', 'day')
      this.EndDate = this.getUTC('start', 'day', 1)
      this.WeeklyCode = ''
      this.CourseId = ''
      this.courseSelected = [...this.courses]
    },
    async getConfigInfo_RCOM01() {
      const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
      if (response.Status === '200') {
        this.FrequenceDefalut = response?.Data.find(item => item.KeyCode === 'BOOKING_TEETIME_FREQUENCE').KeyValue
        if(response?.Data.find(item => item.KeyCode === 'BOOKING_ALLOW_INPUT_NUMBER_OF_GOLFER_ON_FLY')) {
          this.isUpNumberOnFly = response?.Data.find(item => item.KeyCode === 'BOOKING_ALLOW_INPUT_NUMBER_OF_GOLFER_ON_FLY').KeyValue == 1 ? true : false
        }
        this.Frequence = this.FrequenceDefalut
      }
    },
  },
}
