/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  API_RTT01,
  API_RTE01, API_ITT01, API_UTT02, API_DTT01, API_UTT01,
  API_RTT02
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListTeeTime(data) {
  return fetch(ROOT + API_RTT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getListHistoryTeeTime(data) {
  return fetch(ROOT + API_RTT02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function getListTee(data) {
  return fetch(ROOT + API_RTE01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function saveTee(data) {
  return fetch(ROOT + API_ITT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function blockTee(data) {
  return fetch(ROOT + API_UTT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function unBlockTee(data) {
  return fetch(ROOT + API_UTT02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteTee(data) {
  return fetch(ROOT + API_DTT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const teeSheet = {
  getListTeeTime,
  getListTee,
  saveTee,
  blockTee,
  unBlockTee,
  deleteTee,
  getListHistoryTeeTime
}
