import { render, staticRenderFns } from "./SidebarContent.vue?vue&type=template&id=4201e2aa&scoped=true&%23default=%7B%20hide%20%7D"
import script from "./SidebarContent.js?vue&type=script&lang=js&external"
export * from "./SidebarContent.js?vue&type=script&lang=js&external"
import style0 from "./SidebarContent.vue?vue&type=style&index=0&id=4201e2aa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4201e2aa",
  null
  
)

export default component.exports