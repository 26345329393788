import vSelect from 'vue-select'
import { courseSetting } from '@/api/course-setting'
import { teeSheet } from '@/api/tee-sheet'
import SidebarContent from './SidebarContent/SidebarContent.vue'
import History from './History.vue'
export default {
  name: 'TeeSheet',
  components: {
    vSelect, SidebarContent,History
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false,
      isLoading: false,
      targetDrop: null,
      selectedCourse: null,
      courses: [],
      fields: [],
      teeTimeInfo: [],
      chooseDate: this.getUTC('start', 'day'),
      selectedTeeTime: [],
      action: null,
    }
  },

  created() {
    this.isLoading = true
    this.doubleRaf(async () => {
      await this.getListCourse()
      await this.getListTeeTime_API_RTT01()
      this.isLoading = false
    })
  },

  methods: {
    refresh() {
      this.isLoading = true
      this.doubleRaf(() => {
        this.isLoading = false
      })
    },

    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },

    async getListCourse() {
      const response = await courseSetting.getListCourse()
      if (response.Status === '200') {
        this.courses = response?.Data?.Course
        this.selectedCourse = response?.Data?.Course[0]
      }
    },

    async getListTeeTime_API_RTT01() {
      const CourseId = this.selectedCourse.Id
      const ChooseDate = this.chooseDate

      const responseTeeTime = await teeSheet.getListTeeTime({ CourseId, ChooseDate })
      const responseTee = await teeSheet.getListTee({ Course: { Id: CourseId } })
      
      // API_RTE01 get list tee

      if (responseTeeTime && responseTee.Status === '200') {
        this.teeTimeInfo = responseTeeTime?.Data?.TeeTimeInfo
        this.teeTimeInfo.forEach(item => item.checked = false)
        this.fields = responseTee?.Data?.Tee.map(item => item?.TeeName)
      } else {
        this.showResToast(responseTeeTime)
      }
    },

    async handleEvent() {
      await this.getListTeeTime_API_RTT01()
    },

    async searchTee() {
      await this.getListTeeTime_API_RTT01()
    },

    handleClickTeeTimeCell(_teeTime, added = true) {
      let teeTimeRep = this.selectedTeeTime
      const exist = this.selectedTeeTime.find(item => item.Id === _teeTime.Id)
      if (exist && !added) {
        teeTimeRep = teeTimeRep.filter(item => item.Id !== _teeTime.Id)
      }

      if (!exist && added) {
        teeTimeRep.push(_teeTime)
      }

      this.selectedTeeTime = teeTimeRep
      this.$forceUpdate()
    },

    handleClickTeeTimeDelete() {
      if (!this.selectedTeeTime) this.showToast('success', this.$t('tee_time_unpicked'))
      else this.$bvModal.show('modal-center')
    },

    async blockTeeTime_API_UTT01() {
      if (this.selectedTeeTime.length === 0) {
        this.showToast('error', this.$t('tee_time_unpicked'))
        return
      }

      const teeTimeParams = this.selectedTeeTime.map(item => ({ Id: item.Id, IsForce: false }))
      const data = { TeeTime: teeTimeParams }

      const response = await teeSheet.blockTee(data)
      if (response.Status === '200') {
        await this.getListTeeTime_API_RTT01()
        this.showToast('success', this.$t('block_tee_ok'))
        this.refreshData()
      } else {
        this.showToast('error', this.$t('golf_common_error'))
      }
    },

    async unBlockTeeTime_API_UTT02() {
      if (this.selectedTeeTime.length === 0) {
        this.showToast('error', this.$t('tee_time_unpicked'))
        return
      }

      const teeTimeParams = this.selectedTeeTime.map(item => ({ Id: item.Id }))
      const data = { TeeTime: teeTimeParams }

      const response = await teeSheet.unBlockTee(data)
      if (response.Status === '200') {
        await this.getListTeeTime_API_RTT01()
        this.showToast('success', this.$t('unblock_tee_ok'))
        this.refreshData()
      } else {
        this.showToast('error', this.$t('golf_common_error'))
      }
    },

    async deleteTeeTime_API_DTT01() {
      if (this.selectedTeeTime.length === 0) {
        this.showToast('error', this.$t('tee_time_unpicked'))
        return
      }

      const teeTimeParams = this.selectedTeeTime.filter(y => y.IsDeleted === false).map(item => ({ Id: item.Id, IsForce: false }))
      const data = { TeeTime: teeTimeParams }

      const response = await teeSheet.deleteTee(data)
      if (response.Status === '200') {
        await this.getListTeeTime_API_RTT01()
        this.showToast('success', this.$t('delete_tee_ok'))
        this.refreshData()
      } else {
        this.showToast('error', this.$t('golf_common_error'))
      }
    },

    hideModal() {
      this.$bvModal.hide('modal-center')
    },

    refreshData() {
      this.selectedTeeTime = []
    },

    checkAll(checked) {
      // selected all row
      for (const item of this.teeTimeInfo) {
        item.checked = checked
        for (const tee of item.TeeTime) {
          this.handleClickTeeTimeCell(tee, checked)
        }
      }
      this.$forceUpdate()
    },

    checkEnableButton(type) {
      if (type === 'delete') {
        return this.selectedTeeTime.length > 0
      }

      if (type === 'block') {
        return this.selectedTeeTime.filter(item => !item.IsBlock).length > 0
      }

      if (type === 'un_block') {
        return this.selectedTeeTime.filter(item => item.IsBlock).length > 0
      }
    },

    // select one row
    checkOne(item, checked) {
      for (const tee of item.TeeTime) {
        this.handleClickTeeTimeCell(tee, checked)
      }
      // this.allSelected = false
    },
    openHistory() {
      this.$refs.History.open()
    }
  },
}
